import React from 'react';
import { graphql } from "gatsby";
import parse from 'html-react-parser';

import Layout from "../../components/layout"
import Seo from "../../components/seo"

//sections
import SinglePost from '../sections/singleBlogPost/SinglePost';

const ImportanceOfIllustrationAndIconography = ({data}) => {
  const mainData = data.allWpPage.nodes[0].importanceOfIllustrationsACF;
  return (
    <Layout>
      <Seo title="Importance of Illustrations and Iconography in Product Design" />
      <div className="page-wrapper single-blog-page" id="single-blog-page-wrapper">
        <SinglePost 
            blogUrl={mainData.blogHeaderBackUrl}
            blogTitle={parse(mainData.blogHeaderTitle)}
            tags={mainData.blogHeaderTagList}
            minutesRead={parse(mainData.bogHeaderMinReads)}
            blogDate={mainData.blogHeaderDate}
            image1x={
              {
                sourceUrl: mainData.blogHeaderImage1x.sourceUrl,
                altText: mainData.blogHeaderImage1x.altText,
                width: mainData.blogHeaderImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.blogHeaderImage2x.sourceUrl,
                altText: mainData.blogHeaderImage2x.altText,
                width: mainData.blogHeaderImage2x.width,
              }
            }
            tableOfContentsList={mainData.blog2TableOfContents}
            blogContentList={mainData.blog2Content}
            footerTitle={parse(mainData.blog2FooterTitle)}
            footerDescription={parse(mainData.blog2FooterDescription)}
            workList={mainData.blog2FooterWorkList}
        />
      </div>
    </Layout>
  )
}

export const singleBlogQuery = graphql`
{
    allWpPage(filter: {slug: {eq: "importance-of-illustrations-and-iconography"}}) {
        nodes {
          importanceOfIllustrationsACF {
            blogHeaderBackUrl
            blogHeaderTitle
            blogHeaderTagList {
              tagName
            }
            bogHeaderMinReads
            blogHeaderDate
            blogHeaderImage1x {
              altText
              sourceUrl
              width
            }
            blogHeaderImage2x {
              altText
              sourceUrl
              width
            }
            blog2TableOfContents {
              contentName
              extraClasses
              id
            }
            blog2Content {
              contentName
              contentDescription
              contentImageList {
                image1x {
                  altText
                  sourceUrl
                  width
                }
                image2x {
                  altText
                  sourceUrl
                  width
                }
              }
              contentSecondDescription
              contentSecondImageList {
                image1x {
                  altText
                  sourceUrl
                  width
                }
                image2x {
                  altText
                  sourceUrl
                  width
                }
              }
              id
            }
            blog2FooterTitle
            blog2FooterDescription
            blog2FooterWorkList {
              blogUrl
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
              workTitle
              workDescription
              workCategory
            }
          }
        }
      }
}`

export default ImportanceOfIllustrationAndIconography;

